import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useInterfaceStore = defineStore('interface', () => {
  const showHeaderBar = ref(true)
  const showNavigationBar = ref(true)
  const showPOS = ref(false)
  const path = ref('')
  const previousPath = ref('')

  function showBars (show) {
    showHeaderBar.value = show
    showNavigationBar.value = show
  }

  function setPath (newPath) {
    path.value = newPath
  }

  function setPreviousPath (path) {
    previousPath.value = path
  }

  function showScrollBar (show) {
    document.body.style.overflow = show ? 'auto' : 'hidden'
  }

  function showPOSInterface (show) {
    showPOS.value = show
    showScrollBar(!show)
  }

  return { showHeaderBar, showNavigationBar, showBars, setPath, path, setPreviousPath, previousPath, showScrollBar, showPOS, showPOSInterface }
})
