import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

function dateString (date) {
  if (!date?.getDate) return date
  const dateString = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const monthString = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  return `${dateString}/${monthString}/${date.getFullYear()}`
}

export const useReportsStore = defineStore('reports', () => {
  const initialDate = ref(null)
  const finalDate = ref(null)

  const isDownloading = ref(false)
  const isCreating = ref(false)

  const orders = ref([])
  const byMonth = ref({ data: {} })
  const byDate = ref({ data: {} })
  const byCustomer = ref({ data: {} })
  const bySaler = ref({ data: {} })
  const byProduct = ref({ data: {} })
  const byOrder = ref({ data: {} })

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  function initData (initDates = true) {
    if (initDates) {
      initialDate.value = null
      finalDate.value = null
    }
    orders.value = []
    byMonth.value = { data: {} }
    byDate.value = { data: {} }
    byCustomer.value = { data: {} }
    bySaler.value = { data: {} }
    byProduct.value = { data: {} }
    byOrder.value = { data: {} }
  }

  async function downloadData () {
    try {
      initData(false)

      const variables = { nit: user.company.nit, initialDate: initialDate.value, finalDate: finalDate.value }
      const response = await graphql.sendRequest('GET_ORDERS_BY_DATE', variables)

      const ordersFound = response?.data?.getOrdersByDate
      if (!ordersFound) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      orders.value = ordersFound

      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function buildData () {
    return new Promise((resolve) => {
      setTimeout(async () => {
        orders.value.forEach(({ saler, products, customer, createdAt, creditNotes, debitNotes, id, invoice }) => {
          const orderDate = new Date(createdAt)
          const month = orderDate.getMonth() + 1
          const date = dateString(orderDate)
          const documentNumber = customer.documentNumber
          const nameCustomer = customer.name
          const nameSaler = saler || 'ninguno'

          const fields = { order: 0, subtotal: 0, discount: 0, tax: 0, taxHealthy: 0, total: 0, quantity: 0, orderCredit: 0, subtotalCredit: 0, discountCredit: 0, taxCredit: 0, taxHealthyCredit: 0, totalCredit: 0, quantityCredit: 0, orderDebit: 0, subtotalDebit: 0, discountDebit: 0, taxDebit: 0, taxHealthyDebit: 0, totalDebit: 0, quantityDebit: 0, realTotal: 0 }

          if (!byMonth.value.data[month]) byMonth.value.data[month] = JSON.parse(JSON.stringify(fields))
          if (!byDate.value.data[date]) byDate.value.data[date] = JSON.parse(JSON.stringify(fields))
          if (!byCustomer.value.data[documentNumber]) byCustomer.value.data[documentNumber] = JSON.parse(JSON.stringify(fields))
          byCustomer.value.data[documentNumber].name = nameCustomer
          if (!bySaler.value.data[nameSaler]) bySaler.value.data[nameSaler] = JSON.parse(JSON.stringify(fields))
          bySaler.value.data[nameSaler].name = nameSaler
          if (!byOrder.value.data[id]) byOrder.value.data[id] = JSON.parse(JSON.stringify(fields))
          byOrder.value.data[id].id = id
          if (invoice?.document) byOrder.value.data[id].document = invoice.document

          if (!byMonth.value.totals) byMonth.value.totals = JSON.parse(JSON.stringify(fields))
          if (!byDate.value.totals) byDate.value.totals = JSON.parse(JSON.stringify(fields))
          if (!byCustomer.value.totals) byCustomer.value.totals = JSON.parse(JSON.stringify(fields))
          if (!bySaler.value.totals) bySaler.value.totals = JSON.parse(JSON.stringify(fields))
          if (!byOrder.value.totals) byOrder.value.totals = JSON.parse(JSON.stringify(fields))

          byMonth.value.data[month].order += 1
          byMonth.value.totals.order += 1

          byDate.value.data[date].order += 1
          byDate.value.totals.order += 1

          byCustomer.value.data[documentNumber].order += 1
          byCustomer.value.totals.order += 1

          bySaler.value.data[nameSaler].order += 1
          bySaler.value.totals.order += 1

          const productsAdded = {}

          products.forEach(product => {
            const subtotal = getSubtotalItem(product)
            const tax = getTaxItem(product)
            const taxHealthy = getTaxItemHealthy(product)
            const discount = getDiscountItem(product)
            const total = subtotal - discount + tax + taxHealthy
            const code = product.code
            if (!byProduct.value.data[code]) byProduct.value.data[code] = JSON.parse(JSON.stringify(fields))
            if (!byProduct.value.totals) byProduct.value.totals = JSON.parse(JSON.stringify(fields))
            byProduct.value.data[code].name = product.name

            if (!productsAdded[code]) {
              byProduct.value.data[code].order += 1
              byProduct.value.totals.order += 1
              productsAdded[code] = true
            }

            incrementByMonth({ month, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
            incrementByDate({ date, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
            incrementBySaler({ nameSaler, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
            incrementByCustomer({ documentNumber, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
            incrementByProduct({ code: product.code, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
            incrementByOrder({ id, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity })
          })

          creditNotes.forEach(({ products, document }) => {
            byMonth.value.data[month].orderCredit += 1
            byMonth.value.totals.orderCredit += 1

            byDate.value.data[date].orderCredit += 1
            byDate.value.totals.orderCredit += 1

            byCustomer.value.data[documentNumber].orderCredit += 1
            byCustomer.value.totals.orderCredit += 1

            bySaler.value.data[nameSaler].orderCredit += 1
            bySaler.value.totals.orderCredit += 1

            if (document?.document) byOrder.value.data[id].documentCredit = document.document

            const productsAddedCredit = {}

            products.forEach(product => {
              const subtotal = getSubtotalItem(product)
              const tax = getTaxItem(product)
              const taxHealthy = getTaxItemHealthy(product)
              const discount = getDiscountItem(product)
              const total = subtotal - discount + tax + taxHealthy
              const code = product.code
              if (!byProduct.value.data[code]) byProduct.value.data[code] = JSON.parse(JSON.stringify(fields))
              if (!byProduct.value.totals) byProduct.value.totals = JSON.parse(JSON.stringify(fields))
              byProduct.value.data[code].name = product.name

              if (!productsAddedCredit[code]) {
                byProduct.value.data[code].orderCredit += 1
                byProduct.value.totals.orderCredit += 1
                productsAddedCredit[code] = true
              }

              incrementByMonth({ month, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
              incrementByDate({ date, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
              incrementBySaler({ nameSaler, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
              incrementByCustomer({ documentNumber, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
              incrementByProduct({ code: product.code, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
              incrementByOrder({ id, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Credit' })
            })
          })

          debitNotes.forEach(({ products, document }) => {
            byMonth.value.data[month].orderDebit += 1
            byMonth.value.totals.orderDebit += 1

            byDate.value.data[date].orderDebit += 1
            byDate.value.totals.orderDebit += 1

            byCustomer.value.data[documentNumber].orderDebit += 1
            byCustomer.value.totals.orderDebit += 1

            bySaler.value.data[nameSaler].orderDebit += 1
            bySaler.value.totals.orderDebit += 1

            if (document?.document) byOrder.value.data[id].documentDebit = document.document

            const productsAddedDebit = {}

            products.forEach(product => {
              const subtotal = getSubtotalItem(product)
              const tax = getTaxItem(product)
              const taxHealthy = getTaxItemHealthy(product)
              const discount = getDiscountItem(product)
              const total = subtotal - discount + tax + taxHealthy
              const code = product.code
              if (!byProduct.value.data[code]) byProduct.value.data[code] = JSON.parse(JSON.stringify(fields))
              if (!byProduct.value.totals) byProduct.value.totals = JSON.parse(JSON.stringify(fields))
              byProduct.value.data[code].name = product.name

              if (!productsAddedDebit[code]) {
                byProduct.value.data[code].orderDebit += 1
                byProduct.value.totals.orderDebit += 1
                productsAddedDebit[code] = true
              }

              incrementByMonth({ month, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
              incrementByDate({ date, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
              incrementBySaler({ nameSaler, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
              incrementByCustomer({ documentNumber, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
              incrementByProduct({ code: product.code, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
              incrementByOrder({ id, subtotal, discount, tax, taxHealthy, total, quantity: product.quantity, type: 'Debit' })
            })
          })
        })

        getIVAByProduct()
        resolve()
      }, 100)
    })
  }

  function incrementByMonth ({ month, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    byMonth.value.data[month][`subtotal${type || ''}`] += subtotal
    byMonth.value.data[month][`discount${type || ''}`] += discount
    byMonth.value.data[month][`tax${type || ''}`] += tax
    byMonth.value.data[month][`taxHealthy${type || ''}`] += taxHealthy
    byMonth.value.data[month][`total${type || ''}`] += total
    byMonth.value.data[month][`quantity${type || ''}`] += quantity
    byMonth.value.data[month].realTotal += type === 'Credit' ? -total : total

    byMonth.value.totals[`subtotal${type || ''}`] += subtotal
    byMonth.value.totals[`discount${type || ''}`] += discount
    byMonth.value.totals[`tax${type || ''}`] += tax
    byMonth.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    byMonth.value.totals[`total${type || ''}`] += total
    byMonth.value.totals[`quantity${type || ''}`] += quantity
    byMonth.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function incrementByDate ({ date, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    byDate.value.data[date][`subtotal${type || ''}`] += subtotal
    byDate.value.data[date][`discount${type || ''}`] += discount
    byDate.value.data[date][`tax${type || ''}`] += tax
    byDate.value.data[date][`taxHealthy${type || ''}`] += taxHealthy
    byDate.value.data[date][`total${type || ''}`] += total
    byDate.value.data[date][`quantity${type || ''}`] += quantity
    byDate.value.data[date].realTotal += type === 'Credit' ? -total : total

    byDate.value.totals[`subtotal${type || ''}`] += subtotal
    byDate.value.totals[`discount${type || ''}`] += discount
    byDate.value.totals[`tax${type || ''}`] += tax
    byDate.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    byDate.value.totals[`total${type || ''}`] += total
    byDate.value.totals[`quantity${type || ''}`] += quantity
    byDate.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function incrementByProduct ({ code, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    byProduct.value.data[code][`subtotal${type || ''}`] += subtotal
    byProduct.value.data[code][`discount${type || ''}`] += discount
    byProduct.value.data[code][`tax${type || ''}`] += tax
    byProduct.value.data[code][`taxHealthy${type || ''}`] += taxHealthy
    byProduct.value.data[code][`total${type || ''}`] += total
    byProduct.value.data[code][`quantity${type || ''}`] += quantity
    byProduct.value.data[code].realTotal += type === 'Credit' ? -total : total

    byProduct.value.totals[`subtotal${type || ''}`] += subtotal
    byProduct.value.totals[`discount${type || ''}`] += discount
    byProduct.value.totals[`tax${type || ''}`] += tax
    byProduct.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    byProduct.value.totals[`total${type || ''}`] += total
    byProduct.value.totals[`quantity${type || ''}`] += quantity
    byProduct.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function incrementByOrder ({ id, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    byOrder.value.data[id][`subtotal${type || ''}`] += subtotal
    byOrder.value.data[id][`discount${type || ''}`] += discount
    byOrder.value.data[id][`tax${type || ''}`] += tax
    byOrder.value.data[id][`taxHealthy${type || ''}`] += taxHealthy
    byOrder.value.data[id][`total${type || ''}`] += total
    byOrder.value.data[id][`quantity${type || ''}`] += quantity
    byOrder.value.data[id].realTotal += type === 'Credit' ? -total : total

    byOrder.value.totals[`subtotal${type || ''}`] += subtotal
    byOrder.value.totals[`discount${type || ''}`] += discount
    byOrder.value.totals[`tax${type || ''}`] += tax
    byOrder.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    byOrder.value.totals[`total${type || ''}`] += total
    byOrder.value.totals[`quantity${type || ''}`] += quantity
    byOrder.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function getIVAByProduct () {
    for (const code in byProduct.value.data) {
      const product = byProduct.value.data[code]
      product.percent = product.tax / product.subtotal * 100
    }
  }

  function incrementByCustomer ({ documentNumber, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    byCustomer.value.data[documentNumber][`subtotal${type || ''}`] += subtotal
    byCustomer.value.data[documentNumber][`discount${type || ''}`] += discount
    byCustomer.value.data[documentNumber][`tax${type || ''}`] += tax
    byCustomer.value.data[documentNumber][`taxHealthy${type || ''}`] += taxHealthy
    byCustomer.value.data[documentNumber][`total${type || ''}`] += total
    byCustomer.value.data[documentNumber][`quantity${type || ''}`] += quantity
    byCustomer.value.data[documentNumber].realTotal += type === 'Credit' ? -total : total

    byCustomer.value.totals[`subtotal${type || ''}`] += subtotal
    byCustomer.value.totals[`discount${type || ''}`] += discount
    byCustomer.value.totals[`tax${type || ''}`] += tax
    byCustomer.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    byCustomer.value.totals[`total${type || ''}`] += total
    byCustomer.value.totals[`quantity${type || ''}`] += quantity
    byCustomer.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function incrementBySaler ({ nameSaler, subtotal, discount, tax, taxHealthy, total, quantity, type }) {
    bySaler.value.data[nameSaler][`subtotal${type || ''}`] += subtotal
    bySaler.value.data[nameSaler][`discount${type || ''}`] += discount
    bySaler.value.data[nameSaler][`tax${type || ''}`] += tax
    bySaler.value.data[nameSaler][`taxHealthy${type || ''}`] += taxHealthy
    bySaler.value.data[nameSaler][`total${type || ''}`] += total
    bySaler.value.data[nameSaler][`quantity${type || ''}`] += quantity
    bySaler.value.data[nameSaler].realTotal += type === 'Credit' ? -total : total

    bySaler.value.totals[`subtotal${type || ''}`] += subtotal
    bySaler.value.totals[`discount${type || ''}`] += discount
    bySaler.value.totals[`tax${type || ''}`] += tax
    bySaler.value.totals[`taxHealthy${type || ''}`] += taxHealthy
    bySaler.value.totals[`total${type || ''}`] += total
    bySaler.value.totals[`quantity${type || ''}`] += quantity
    bySaler.value.totals.realTotal += type === 'Credit' ? -total : total
  }

  function getPriceItem (product) {
    const { price, tax, taxHealthy, includedTax, includedTaxHealthy } = product

    const totalTaxes = (includedTax ? tax : 0) + (includedTaxHealthy ? taxHealthy : 0)

    return price / (1 + (totalTaxes / 100))
  }

  function getSubtotalItem (product) {
    const { quantity } = product
    const priceWithoutTaxes = getPriceItem(product)

    return priceWithoutTaxes * quantity
  }

  function getTaxItem (product) {
    const { quantity, tax } = product

    const priceWithoutTaxes = getPriceItem(product)
    return ((priceWithoutTaxes * quantity) - getDiscountItem(product)) * (tax / 100)
  }

  function getTaxItemHealthy (product) {
    const { quantity, taxHealthy } = product

    const priceWithoutTaxes = getPriceItem(product)

    return ((priceWithoutTaxes * quantity) - getDiscountItem(product)) * (taxHealthy / 100)
  }

  function getDiscountItem (product) {
    const { quantity, discount } = product
    const priceWithoutTaxes = getPriceItem(product)

    return priceWithoutTaxes * quantity * (discount / 100)
  }

  function setInitialDate (date) {
    initialDate.value = date
  }

  function setFinalDate (date) {
    finalDate.value = date
  }

  async function createReports () {
    isDownloading.value = true
    await downloadData()
    isDownloading.value = false

    isCreating.value = true
    await buildData()
    isCreating.value = false
  }

  return { isDownloading, isCreating, initialDate, finalDate, setInitialDate, setFinalDate, createReports, byMonth, byDate, byCustomer, byProduct, bySaler, byOrder, initData }
})
