import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { useInterfaceStore } from '@/stores/interface'
import { useUserStore } from '@/stores/user'

import number from '@/soduqModules/number'

const pinia = createPinia()
const app = createApp(App)

const addValueToString = (value, position, addValue) => {
  if (!value) {
    return ''
  } else {
    const array = value.split('')

    if (position > array.length - 1) {
      return value
    } else {
      array.splice(position, 0, addValue)
      return array.join('')
    }
  }
}

app.config.globalProperties.$filters = {
  firstLetter (value) {
    if (!value) return ''
    return value.trim()[0]
  },
  firstName (value) {
    if (!value) return ''
    return value.trim().split(' ')[0]
  },
  money (value, wordFree = '', decimals = 0, symbol = '$') {
    if (wordFree && value === 0) return wordFree
    if (value === 0) return '$ 0'
    if (!value) return value

    try {
      return `${symbol} ${new Intl.NumberFormat('es-CO').format(value.toFixed(decimals))}`
    } catch (error) {
      return value
    }
  },
  mobile (value) {
    return addValueToString(addValueToString(value, 6, ' '), 3, ' ')
  },
  capitalize (value) {
    if (!value) return ''

    const array = value.split(' ')

    for (let i = 0; i < array.length; i++) {
      const element = array[i]

      if (array[i].length === 1) continue
      array[i] = element.charAt(0).toUpperCase() + element.slice(1)
    }

    return array.join(' ')
  },
  date (date) {
    if (!date?.getDate) return date
    const dateString = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const monthString = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${dateString}/${monthString}/${date.getFullYear()}`
  },
  numberToLetters (value) {
    return number(value).toLowerCase()
  },

  companyName (value) {
    if (!value) return value
    return this.capitalize(value).replace('Sas', 'S.A.S.')
  },

  percent (value) {
    if (!(value > 0)) return value
    return this.money(value, '', 0, '')
  }
}

app
  .use(router)
  .use(pinia)
  .mount('#app')

const userInterface = useInterfaceStore()
const user = useUserStore()

router.beforeEach((to, from, next) => {
  const path = to.path

  if (path === '/login' || path === '/chooseCompany') {
    userInterface.showBars(false)
  } else {
    userInterface.showBars(true)
  }

  userInterface.setPath(path)
  userInterface.setPreviousPath(from.path)

  const isLoggued = user.data.dni
  const companyChoosed = user.company.nit

  if (path === '/login') {
    if (isLoggued && companyChoosed) {
      router.push('/sales')
    }
  }

  if (isLoggued && !companyChoosed && path !== '/chooseCompany') {
    router.push('/chooseCompany')
  }

  next()
})
