import login from './login'
import user from './user'
import product from './product'
import customer from './customer'
import order from './order'
import note from './note'
import location from './location'
import invoice from './invoice'
import saler from './saler'
import config from './config'

// Queries
export default {
  // Login
  LOGIN: login.LOGIN,
  SEND_VERIFICATION_CODE: login.SEND_VERIFICATION_CODE,

  // User
  GET_USERS: user.GET_DATA,

  // Config
  GET_CONFIGS: config.GET_DATA,

  // Product
  GET_PRODUCTS: product.GET_DATA,
  GET_PRODUCTS_POINT_OF_SALE: product.GET_DATA_POINT_OF_SALE,
  GET_PRODUCTS_BAR_CODE: product.GET_DATA_BAR_CODE,
  GET_PRODUCTS_FILTERED: product.GET_FILTERED,
  CREATE_PRODUCT: product.CREATE,
  UPDATE_PRODUCT: product.UPDATE,

  // Customer
  GET_CUSTOMERS: customer.GET_DATA,
  GET_CUSTOMERS_FILTERED: customer.GET_FILTERED,
  CREATE_CUSTOMER: customer.CREATE,
  UPDATE_CUSTOMER: customer.UPDATE,

  // Location
  GET_LOCATIONS_FILTERED: location.GET_DATA_FILTERED,

  // Order
  GET_ORDERS: order.GET_DATA,
  GET_ORDERS_BY_DATE: order.GET_DATA_BY_DATE,
  CREATE_ORDER: order.CREATE,
  UPDATE_ORDER: order.UPDATE,
  RECORD_ORDER: order.RECORD,
  CREATE_CREDIT_NOTE: order.CREATE_CREDIT_NOTE,
  UPDATE_CREDIT_NOTE: order.UPDATE_CREDIT_NOTE,
  RECORD_CREDIT_NOTE: order.RECORD_CREDIT_NOTE,
  CORRECT_CREDIT_NOTE: order.CORRECT_CREDIT_NOTE,
  CREATE_DEBIT_NOTE: order.CREATE_DEBIT_NOTE,
  UPDATE_DEBIT_NOTE: order.UPDATE_DEBIT_NOTE,
  RECORD_DEBIT_NOTE: order.RECORD_DEBIT_NOTE,
  CORRECT_DEBIT_NOTE: order.CORRECT_DEBIT_NOTE,

  // Note
  GET_NOTES: note.GET_DATA,
  CREATE_NOTE: note.CREATE,
  UPDATE_NOTE: note.UPDATE,
  RECORD_NOTE: note.RECORD,

  // Invoice
  CREATE_INVOICE: invoice.CREATE,
  CREATE_INVOICE_STRONG: invoice.CREATE_STRONG,
  VERIFY_STATUS_INVOICE: invoice.VERIFY_STATUS,
  RESEND_EMAIL: invoice.RESEND_EMAIL,
  CREATE_INVOICE_CREDIT: invoice.CREATE_CREDIT,
  VERIFY_STATUS_CREDIT: invoice.VERIFY_STATUS_CREDIT,

  // Saler
  GET_SALERS_FILTERED: saler.GET_FILTERED
}
