import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useCustomersStore = defineStore('customers', () => {
  const data = ref([])
  const dataFiltered = ref([])
  const isLoadingDataFiltered = ref(false)
  const pageInfo = ref({})
  const filters = ref({})

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getCustomers (page = 0) {
    try {
      const variables = { nit: user.company.nit, filters: filters.value, page: page >= 0 ? page : 0 }

      const response = await graphql.sendRequest('GET_CUSTOMERS', variables)

      if (!response?.data?.getCustomers?.docs) throw new Error('Error al obtener los clientes, comunícate con nosotros para más información')
      data.value = response.data.getCustomers.docs
      delete response.data.getCustomers.docs
      pageInfo.value = response.data.getCustomers
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function getCustomersFiltered (search) {
    isLoadingDataFiltered.value = true

    try {
      const variables = { search, nit: user.company.nit }
      const response = await graphql.sendRequest('GET_CUSTOMERS_FILTERED', variables)

      if (!response?.data?.getCustomersFiltered) throw new Error('Error al obtener los clientes, comunícate con nosotros para más información')
      dataFiltered.value = response?.data?.getCustomersFiltered
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }

    isLoadingDataFiltered.value = false
  }

  async function createCustomer (customer) {
    try {
      const variables = { nit: user.company.nit, input: customer }
      const response = await graphql.sendRequest('CREATE_CUSTOMER', variables)

      const createdCustomer = response?.data?.createCustomer
      if (!createdCustomer) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `El cliente '${createdCustomer.name}' con identificación '${createdCustomer.documentNumber}' ha sido creado`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateCustomer (customer) {
    try {
      const variables = { nit: user.company.nit, input: customer }
      const response = await graphql.sendRequest('UPDATE_CUSTOMER', variables)

      const updatedCustomer = response?.data?.updateCustomer
      if (!updatedCustomer) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalCustomer(response?.data?.updateCustomer)

      notification.showNotification({ text: `El cliente '${updatedCustomer.name}' con identificación '${updatedCustomer.documentNumber}' ha sido actualizado`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  function updateLocalCustomer (customer) {
    const index = data.value.findIndex(({ documentType, documentNumber }) => (documentType === customer.documentType) && (documentNumber === customer.documentNumber))
    if (index >= 0) data.value[index] = customer
  }

  function setFilters (newFilters) {
    filters.value = newFilters
  }

  function initData () {
    data.value = []
  }

  function initDataFiltered () {
    dataFiltered.value = []
  }

  async function setPage (page) {
    await getCustomers(page)
  }

  return { data, getCustomers, setFilters, createCustomer, initData, pageInfo, setPage, updateCustomer, getCustomersFiltered, dataFiltered, initDataFiltered, isLoadingDataFiltered }
})
