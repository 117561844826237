const development = {
  graphql: {
    uri: 'http://localhost:3001/graphql',
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },

      mutate: {
        errorPolicy: 'all'
      }
    }
  }
}

const production = JSON.parse(JSON.stringify(development))
production.graphql.uri = 'https://db.sudoq.co/graphql'

module.exports = process.env.NODE_ENV === 'production' ? production : development
