const order = `{
  id,
  customer {
    documentType,
    documentNumber,
    verificationDigit,
    personType,
    name,
    commercialName,
    emails,
    phones,
    addresses,
    location,
    department
  },
  products {
    name,
    code,
    price,
    quantity,
    discount,
    tax,
    includedTax,
    measureType,
    measureUnit,
    taxHealthy,
    includedTaxHealthy
  },
  invoice {
    type,
    status,
    value,
    tax,
    tascode,
    document,
    CUFE,
    process,
    retries,
    customer,
    URL,
    PDF,
    ATTACHED,
    createdAt
  },
  invoiced
  canceled,
  observations,
  saler,
  paymentType,
  dueDate,
  createdAt,
  recorded,
  reteFuente,
  reteIVA,
  reteICA,
  creditNotes {
    products {
      name,
      code,
      price,
      quantity,
      discount,
      tax,
      measureType,
      measureUnit
    },
    document {
      type,
      status,
      value,
      tax,
      tascode,
      document,
      CUDE,
      process,
      retries,
      customer,
      URL,
      PDF,
      ATTACHED,
      createdAt
    },
    observations,
    recorded,
    corrected
  },
  debitNotes {
    products {
      name,
      code,
      price,
      quantity,
      discount,
      tax,
      measureType,
      measureUnit
    },
    document {
      type,
      status,
      value,
      tax,
      tascode,
      document,
      CUDE,
      process,
      retries,
      customer,
      URL,
      PDF,
      ATTACHED,
      createdAt
    },
    observations,
    recorded,
    corrected
  }
}`

const note = `{
  id,
  customer {
    documentType,
    documentNumber,
    verificationDigit,
    personType,
    name,
    commercialName,
    emails,
    phones,
    addresses,
    location,
    department
  },
  products {
    name,
    code,
    price,
    quantity,
    discount,
    tax,
    measureType,
    measureUnit
  },
  invoice {
    type,
    status,
    value,
    tax,
    tascode,
    document,
    CUFE,
    process,
    retries,
    customer,
    URL,
    PDF,
    ATTACHED,
    createdAt
  },
  invoiced
  canceled,
  observations,
  saler,
  paymentType,
  dueDate,
  createdAt,
  recorded,
  reteFuente,
  reteIVA,
  reteICA
}`

export default {
  CREATE: `#graphql
  mutation createInvoice($orderId: String!, $nit: String!) {
    createInvoice(orderId: $orderId, nit: $nit) ${order}
  }`,

  CREATE_STRONG: `#graphql
  mutation createInvoiceStrong($input: InputOrder!, $nit: String!) {
    createInvoiceStrong(input: $input, nit: $nit) ${order}
  }`,

  VERIFY_STATUS: `#graphql
  mutation verifyStatusInvoice($orderId: String!, $nit: String!, $tascode: String!, $type: String, $index: Int) {
    verifyStatusInvoice(orderId: $orderId, nit: $nit, tascode: $tascode, type: $type, index: $index) ${order}
  }`,

  RESEND_EMAIL: `#graphql
  mutation resendEmail($nit: String!, $tascode: String!, $email: String!) {
    resendEmail(nit: $nit, tascode: $tascode, email: $email)
  }`,

  CREATE_CREDIT: `#graphql
  mutation createInvoiceCredit($noteId: String!, $nit: String!) {
    createInvoiceCredit(noteId: $noteId, nit: $nit) ${note}
  }`,

  VERIFY_STATUS_CREDIT: `#graphql
  mutation verifyStatusCredit($noteId: String!, $nit: String!, $tascode: String!, $type: String, $index: Int) {
    verifyStatusCredit(noteId: $noteId, nit: $nit, tascode: $tascode, type: $type, index: $index) ${note}
  }`
}
