import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ProductView from '../views/ProductView.vue'
import CustomerView from '../views/CustomerView.vue'
import UserView from '../views/UserView.vue'
import OrderView from '../views/OrderView.vue'
import NoteView from '../views/NoteView.vue'
import ReportsView from '../views/ReportsView.vue'
import ChooseCompanyView from '../views/ChooseCompanyView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/orders',
    name: 'orders',
    redirect: 'sales'
  },
  {
    path: '/notes',
    name: 'notes',
    component: NoteView
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView
  },
  {
    path: '/sales',
    name: 'sales',
    component: OrderView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductView
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomerView
  },
  {
    path: '/users',
    name: 'users',
    component: UserView
  },
  {
    path: '/chooseCompany',
    name: 'chooseCompany',
    component: ChooseCompanyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
