export default {
  GET_DATA: `#graphql
  query getProducts($filters: Filters!, $page: Int!, $nit: String!) {
    getProducts(filters: $filters, page: $page, nit: $nit) {
      docs {
        code,
        barCode,
        name,
        price1,
        price2,
        tax,
        hasTax,
        includedTax,
        taxHealthy,
        hasTaxHealthy,
        includedTaxHealthy,
        measureType,
        measureUnit,
        observations,
        active,
        image
      },
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }
  }`,

  GET_DATA_POINT_OF_SALE: `#graphql
  query getProductsPointOfSale($filters: Filters!, $page: Int!, $nit: String!) {
    getProductsPointOfSale(filters: $filters, page: $page, nit: $nit) {
      docs {
        code,
        barCode,
        name,
        price1,
        price2,
        tax,
        hasTax,
        includedTax,
        taxHealthy,
        hasTaxHealthy,
        includedTaxHealthy,
        measureType,
        measureUnit,
        observations,
        active,
        image
      },
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }
  }`,

  GET_DATA_BAR_CODE: `#graphql
  query getProductsBarCode($barCode: String!, $nit: String!) {
    getProductsBarCode(barCode: $barCode, nit: $nit) {
      code,
      barCode,
      name,
      price1,
      price2,
      tax,
      hasTax,
      includedTax,
      taxHealthy,
      hasTaxHealthy,
      includedTaxHealthy,
      measureType,
      measureUnit,
      observations,
      active,
      image
    }
  }`,

  GET_FILTERED: `#graphql
  query getProductsFiltered($search: String!, $nit: String!) {
    getProductsFiltered(search: $search, nit: $nit) {
      code,
      name,
      price1,
      price2,
      tax,
      hasTax,
      includedTax,
      taxHealthy,
      hasTaxHealthy,
      includedTaxHealthy,
      measureType,
      measureUnit,
      image
    }
  }`,

  CREATE: `#graphql
  mutation createProduct($input: InputProduct!, $nit: String!) {
    createProduct(input: $input, nit: $nit) {
      code,
      name
    }
  }`,

  UPDATE: `#graphql
  mutation updateProduct($input: InputUpdateProduct!, $nit: String!) {
    updateProduct(input: $input, nit: $nit) {
      code,
      name,
      price1,
      price2,
      tax,
      hasTax,
      includedTax,
      taxHealthy,
      hasTaxHealthy,
      includedTaxHealthy,
      measureType,
      measureUnit,
      observations,
      active
    }
  }`
}
