import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useUsersStore = defineStore('users', () => {
  const data = ref([])
  const pageInfo = ref({})
  const filters = ref({})

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getUsers () {
    try {
      const variables = { nit: user.company.nit, filters: filters.value, page: 0 }
      const response = await graphql.sendRequest('GET_USERS', variables)

      if (!response?.data?.getUsers?.docs) throw new Error('Error al obtener los usuarios, comunícate con nosotros para más información')
      data.value = response.data.getUsers.docs
      delete response.data.getUsers.docs
      pageInfo.value = response.data.getUsers
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  function setFilters (newFilters) {
    filters.value = newFilters
  }

  function initData () {
    data.value = []
  }

  async function setPage (page) {
    await getProducts(page)
  }

  return { data, getUsers, setFilters, initData, pageInfo, setPage }
})
