import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useProductsStore = defineStore('products', () => {
  const data = ref([])
  const dataFiltered = ref([])
  const isLoadingDataFiltered = ref(false)
  const pageInfo = ref({})
  const filters = ref({})

  // POS
  const productsPOS = ref([])

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getProducts (page = 0, customFilters, returnProducts = false) {
    try {
      const variables = { nit: user.company.nit, filters: customFilters || filters.value, page: page >= 0 ? page : 0 }
      const response = await graphql.sendRequest('GET_PRODUCTS', variables)

      if (!response?.data?.getProducts?.docs) throw new Error('Error al obtener los productos, comunícate con nosotros para más información')

      if (returnProducts) {
        return response.data.getProducts.docs
      } else {
        data.value = response.data.getProducts.docs
        delete response.data.getProducts.docs
        pageInfo.value = response.data.getProducts
      }
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function getProductsPointOfSale (page = 0, customFilters) {
    try {
      const variables = { nit: user.company.nit, filters: customFilters || filters.value, page: page >= 0 ? page : 0 }
      const response = await graphql.sendRequest('GET_PRODUCTS_POINT_OF_SALE', variables)

      if (!response?.data?.getProductsPointOfSale?.docs) throw new Error('Error al obtener los productos, comunícate con nosotros para más información')

      return {
        data: response.data.getProductsPointOfSale.docs,
        prevPage: response.data.getProductsPointOfSale.prevPage,
        nextPage: response.data.getProductsPointOfSale.nextPage,
        page: response.data.getProductsPointOfSale.page
      }
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function getProductsBarCode (barCode) {
    try {
      const variables = { nit: user.company.nit, barCode }
      const response = await graphql.sendRequest('GET_PRODUCTS_BAR_CODE', variables)

      if (!response?.data?.getProductsBarCode) throw new Error(`Error al obtener el producto con código ${barCode}`)

      return response.data.getProductsBarCode
    } catch (error) {
      return { error: true, text: error.message }
    }
  }

  async function getProductsFiltered (search, returnError = false) {
    isLoadingDataFiltered.value = true

    try {
      const variables = { search, nit: user.company.nit }
      const response = await graphql.sendRequest('GET_PRODUCTS_FILTERED', variables)

      if (!response?.data?.getProductsFiltered) throw new Error('Error al obtener los productos, comunícate con nosotros para más información')
      dataFiltered.value = response?.data?.getProductsFiltered

      return dataFiltered.value
    } catch (error) {
      if (returnError) return { error: true, text: error.message }
      notification.showNotification({ text: error.message, type: 'error' })
    }

    isLoadingDataFiltered.value = false
  }

  async function createProduct (product) {
    try {
      const variables = { nit: user.company.nit, input: product }
      const response = await graphql.sendRequest('CREATE_PRODUCT', variables)

      const createdProduct = response?.data?.createProduct
      if (!createdProduct) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `El producto '${createdProduct.name}' con código '${createdProduct.code}' ha sido creado`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateProduct (product) {
    try {
      const variables = { nit: user.company.nit, input: product }
      const response = await graphql.sendRequest('UPDATE_PRODUCT', variables)

      const updatedProduct = response?.data?.updateProduct
      if (!updatedProduct) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalProduct(response?.data?.updateProduct)

      notification.showNotification({ text: `El producto '${updatedProduct.name}' con código '${updatedProduct.code}' ha sido actualizado`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  function updateLocalProduct (product) {
    const index = data.value.findIndex(({ code }) => code === product.code)
    if (index >= 0) data.value[index] = product
  }

  function setFilters (newFilters) {
    filters.value = newFilters
  }

  function initData () {
    data.value = []
    productsPOS.value = []
  }

  function initDataFiltered () {
    dataFiltered.value = []
  }

  function initProductsPOS () {
    productsPOS.value = []
  }

  async function setPage (page) {
    await getProducts(page)
  }

  function setProductsPOS (products) {
    productsPOS.value = products
  }

  return { data, getProducts, setFilters, createProduct, initData, pageInfo, setPage, updateProduct, getProductsFiltered, dataFiltered, initDataFiltered, productsPOS, setProductsPOS, isLoadingDataFiltered, initProductsPOS, getProductsBarCode, getProductsPointOfSale }
})
