import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useSalersStore = defineStore('salers', () => {
  const dataFiltered = ref([])
  const isLoadingDataFiltered = ref(false)

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getSalersFiltered (search) {
    isLoadingDataFiltered.value = true

    try {
      const variables = { search, nit: user.company.nit }
      const response = await graphql.sendRequest('GET_SALERS_FILTERED', variables)

      if (!response?.data?.getSalersFiltered) throw new Error('Error al obtener los vendedores, comunícate con nosotros para más información')
      dataFiltered.value = response?.data?.getSalersFiltered
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }

    isLoadingDataFiltered.value = false
  }

  function initDataFiltered () {
    dataFiltered.value = []
  }

  return { getSalersFiltered, dataFiltered, initDataFiltered, isLoadingDataFiltered }
})
