import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useNotesStore = defineStore('notes', () => {
  const data = ref([])
  const pageInfo = ref({})
  const filters = ref({})

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getNotes (page) {
    try {
      const variables = { nit: user.company.nit, filters: filters.value, page: page >= 0 ? page : 0 }
      const response = await graphql.sendRequest('GET_NOTES', variables)

      if (!response?.data?.getNotes?.docs) throw new Error('Error al obtener las notaes, comunícate con nosotros para más información')
      data.value = response.data.getNotes.docs
      delete response.data.getNotes.docs
      pageInfo.value = response.data.getNotes
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createNote (note) {
    try {
      const variables = { nit: user.company.nit, input: note }
      const response = await graphql.sendRequest('CREATE_NOTE', variables)

      console.log(response)

      const createdNote = response?.data?.createNote
      if (!createdNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `La nota '${createdNote.id}' ha sido creada`, type: 'success' })

      data.value.unshift(createdNote)
      return createdNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateNote (note) {
    try {
      const variables = { nit: user.company.nit, input: note }
      const response = await graphql.sendRequest('UPDATE_NOTE', variables)

      const updatedNote = response?.data?.updateNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalNote(response?.data?.updateNote)

      notification.showNotification({ text: `La nota '${updatedNote.id}' ha sido actualizada`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function recordNote (note) {
    try {
      const variables = { nit: user.company.nit, input: note }
      const response = await graphql.sendRequest('RECORD_NOTE', variables)

      const updatedNote = response?.data?.recordNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalNote(response?.data?.recordNote)

      notification.showNotification({ text: `La nota '${updatedNote.id}' ha sido Grabada`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createInvoice (noteId) {
    try {
      const variables = { nit: user.company.nit, noteId }
      const response = await graphql.sendRequest('CREATE_INVOICE_CREDIT', variables)

      const noteUpdated = response?.data?.createInvoiceCredit
      if (!noteUpdated) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha enviado a la DIAN la nota '${noteUpdated.id}'`, type: 'success' })

      const index = data.value.findIndex(({ id }) => id === noteId)

      if (index >= 0) {
        data.value[index].invoice = noteUpdated.invoice
        data.value[index].invoiced = noteUpdated.invoiced
      }

      return noteUpdated
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createInvoiceStrong (note) {
    try {
      const variables = { nit: user.company.nit, input: note }
      const response = await graphql.sendRequest('CREATE_INVOICE_STRONG', variables)

      const noteUpdated = response?.data?.createInvoiceStrong
      if (!noteUpdated) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha creado la factura para la nota '${noteUpdated.id}'`, type: 'success' })

      data.value.unshift(noteUpdated)
      return noteUpdated
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function verifyStatusInvoice (noteId, tascode, type = 'invoice', index) {
    try {
      const variables = { nit: user.company.nit, noteId, tascode, type, index }
      const response = await graphql.sendRequest('VERIFY_STATUS_CREDIT', variables)

      const updatedNote = response?.data?.verifyStatusCredit
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Estado del documento actualizado, nota '${noteId}'`, type: 'success' })
      updateLocalNote(updatedNote)
      return updatedNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function resendEmail (email, tascode) {
    try {
      const variables = { nit: user.company.nit, email, tascode }
      const response = await graphql.sendRequest('RESEND_EMAIL', variables)

      const updatedNote = response?.data?.resendEmail
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `La nota crédito fue reenviada al correo: ${email}`, type: 'success' })
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateCreditNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('UPDATE_CREDIT_NOTE', variables)

      const updatedNote = response?.data?.updateCreditNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha actualizado la nota crédito de la nota '${updatedNote.id}'`, type: 'success' })
      updateLocalNote(updatedNote)
      return updatedNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateDebitNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('UPDATE_DEBIT_NOTE', variables)

      const updatedNote = response?.data?.updateDebitNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha actualizado la nota débito de la nota '${updatedNote.id}'`, type: 'success' })
      updateLocalNote(updatedNote)
      return updatedNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function correctNote (id, index, type) {
    if (type === 'debit') return await correctDebitNote(id, index)
    if (type === 'credit') return await correctCreditNote(id, index)
  }

  async function correctCreditNote (id, index) {
    try {
      const variables = { nit: user.company.nit, noteId: id, index }
      const response = await graphql.sendRequest('CORRECT_CREDIT_NOTE', variables)

      const updatedNote = response?.data?.correctCreditNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha enviado a la DIAN la nota crédito de la nota '${updatedNote.id}'`, type: 'success' })
      updateLocalNote(updatedNote)
      return updatedNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function correctDebitNote (id, index, type) {
    try {
      const variables = { nit: user.company.nit, noteId: id, index }
      const response = await graphql.sendRequest('CORRECT_DEBIT_NOTE', variables)

      const updatedNote = response?.data?.correctDebitNote
      if (!updatedNote) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha enviado a la DIAN la nota débito de la nota '${updatedNote.id}'`, type: 'success' })
      updateLocalNote(updatedNote)
      return updatedNote
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  function updateLocalNote (note) {
    const index = data.value.findIndex(({ id }) => id === note.id)
    if (index >= 0) data.value[index] = note
  }

  function setFilters (newFilters) {
    filters.value = newFilters
  }

  function initData () {
    data.value = []
  }

  async function setPage (page) {
    await getNotes(page)
  }

  return { data, getNotes, setFilters, createNote, initData, pageInfo, setPage, updateNote, recordNote, createInvoice, verifyStatusInvoice, createInvoiceStrong, correctNote, resendEmail }
})
