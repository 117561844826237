import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'
import { useUserStore } from '@/stores/user'

export const useOrdersStore = defineStore('orders', () => {
  const data = ref([])
  const pageInfo = ref({})
  const filters = ref({})

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()
  const user = useUserStore()

  async function getOrders (page) {
    try {
      const variables = { nit: user.company.nit, filters: filters.value, page: page >= 0 ? page : 0 }
      const response = await graphql.sendRequest('GET_ORDERS', variables)

      if (!response?.data?.getOrders?.docs) throw new Error('Error al obtener las ordenes, comunícate con nosotros para más información')
      data.value = response.data.getOrders.docs
      delete response.data.getOrders.docs
      pageInfo.value = response.data.getOrders
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createOrder (order) {
    try {
      const variables = { nit: user.company.nit, input: order }
      const response = await graphql.sendRequest('CREATE_ORDER', variables)

      const createdOrder = response?.data?.createOrder
      if (!createdOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `La orden '${createdOrder.id}' ha sido creada`, type: 'success' })

      data.value.unshift(createdOrder)
      console.log(createdOrder)
      return createdOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateOrder (order) {
    try {
      const variables = { nit: user.company.nit, input: order }
      const response = await graphql.sendRequest('UPDATE_ORDER', variables)

      const updatedOrder = response?.data?.updateOrder
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalOrder(response?.data?.updateOrder)

      notification.showNotification({ text: `La orden '${updatedOrder.id}' ha sido actualizada`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function recordOrder (order) {
    try {
      const variables = { nit: user.company.nit, input: order }
      const response = await graphql.sendRequest('RECORD_ORDER', variables)

      const updatedOrder = response?.data?.recordOrder
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')

      updateLocalOrder(response?.data?.recordOrder)

      notification.showNotification({ text: `La orden '${updatedOrder.id}' ha sido Grabada`, type: 'success' })
      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createInvoice (orderId) {
    try {
      const variables = { nit: user.company.nit, orderId }
      const response = await graphql.sendRequest('CREATE_INVOICE', variables)

      const orderUpdated = response?.data?.createInvoice
      if (!orderUpdated) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha creado la factura para la orden '${orderUpdated.id}'`, type: 'success' })

      const index = data.value.findIndex(({ id }) => id === orderId)

      if (index >= 0) {
        data.value[index].invoice = orderUpdated.invoice
        data.value[index].invoiced = orderUpdated.invoiced
      }

      return orderUpdated
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createInvoiceStrong (order) {
    try {
      const variables = { nit: user.company.nit, input: order }
      const response = await graphql.sendRequest('CREATE_INVOICE_STRONG', variables)

      const orderUpdated = response?.data?.createInvoiceStrong
      if (!orderUpdated) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha creado la factura para la orden '${orderUpdated.id}'`, type: 'success' })

      data.value.unshift(orderUpdated)
      console.log(orderUpdated)
      return orderUpdated
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function verifyStatusInvoice (orderId, tascode, type = 'invoice', index) {
    try {
      const variables = { nit: user.company.nit, orderId, tascode, type, index }
      const response = await graphql.sendRequest('VERIFY_STATUS_INVOICE', variables)

      const updatedOrder = response?.data?.verifyStatusInvoice
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Estado del documento actualizado, orden '${orderId}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function resendEmail (email, tascode, isNote) {
    try {
      const variables = { nit: user.company.nit, email, tascode }
      const response = await graphql.sendRequest('RESEND_EMAIL', variables)

      const updatedOrder = response?.data?.resendEmail
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `La ${isNote ? 'nota' : 'factura'} fue reenviada al correo: ${email}`, type: 'success' })
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createNote (input, id, type) {
    if (type === 'debit') return await createDebitNote(input, id)
    if (type === 'credit') return await createCreditNote(input, id)
  }

  async function createCreditNote (input, id) {
    try {
      const variables = { nit: user.company.nit, input, id }
      const response = await graphql.sendRequest('CREATE_CREDIT_NOTE', variables)

      const updatedOrder = response?.data?.createCreditNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha guardado la nota crédito para la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function createDebitNote (input, id) {
    try {
      const variables = { nit: user.company.nit, input, id }
      const response = await graphql.sendRequest('CREATE_DEBIT_NOTE', variables)

      const updatedOrder = response?.data?.createDebitNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha guardado la nota débito para la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateNote (input, id, index, type) {
    if (type === 'debit') return await updateDebitNote(input, id, index)
    if (type === 'credit') return await updateCreditNote(input, id, index)
  }

  async function updateCreditNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('UPDATE_CREDIT_NOTE', variables)

      const updatedOrder = response?.data?.updateCreditNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha actualizado la nota crédito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updateDebitNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('UPDATE_DEBIT_NOTE', variables)

      const updatedOrder = response?.data?.updateDebitNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha actualizado la nota débito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function recordNote (input, id, index, type) {
    if (type === 'debit') return await recordDebitNote(input, id, index)
    if (type === 'credit') return await recordCreditNote(input, id, index)
  }

  async function recordCreditNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('RECORD_CREDIT_NOTE', variables)

      const updatedOrder = response?.data?.recordCreditNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha grabado la nota crédito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function recordDebitNote (input, id, index) {
    try {
      const variables = { nit: user.company.nit, input, id, index }
      const response = await graphql.sendRequest('RECORD_DEBIT_NOTE', variables)

      const updatedOrder = response?.data?.recordDebitNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha grabado la nota débito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function correctNote (id, index, type) {
    if (type === 'debit') return await correctDebitNote(id, index)
    if (type === 'credit') return await correctCreditNote(id, index)
  }

  async function correctCreditNote (id, index) {
    try {
      const variables = { nit: user.company.nit, orderId: id, index }
      const response = await graphql.sendRequest('CORRECT_CREDIT_NOTE', variables)

      const updatedOrder = response?.data?.correctCreditNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha enviado a la DIAN la nota crédito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function correctDebitNote (id, index, type) {
    try {
      const variables = { nit: user.company.nit, orderId: id, index }
      const response = await graphql.sendRequest('CORRECT_DEBIT_NOTE', variables)

      const updatedOrder = response?.data?.correctDebitNote
      if (!updatedOrder) throw new Error('Error en la respuesta, comunícate con nosotros para más información')
      notification.showNotification({ text: `Se ha enviado a la DIAN la nota débito de la orden '${updatedOrder.id}'`, type: 'success' })
      updateLocalOrder(updatedOrder)
      return updatedOrder
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  function updateLocalOrder (order) {
    const index = data.value.findIndex(({ id }) => id === order.id)
    if (index >= 0) data.value[index] = order
  }

  function setFilters (newFilters) {
    filters.value = newFilters
  }

  function initData () {
    data.value = []
  }

  async function setPage (page) {
    await getOrders(page)
  }

  const printOrder = ref({})
  const printOrderShow = ref(false)
  const printOrderType = ref('')
  const printOrderIsReceipt = ref(false)
  const printOrderTaxes = ref({
    hasReteFuente: false,
    hasReteICA: false,
    hasReteIVA: false
  })

  function setPrintOrder (order, last) {
    if (last) order = data.value[0]
    if (!order) return

    printOrder.value = JSON.parse(JSON.stringify(order))
    if (printOrder.value.hasReteFuente) printOrderTaxes.value.hasReteFuente = printOrder.value.hasReteFuente || false
    if (printOrder.value.hasReteIVA) printOrderTaxes.value.hasReteIVA = printOrder.value.hasReteIVA || false
    if (printOrder.value.hasReteICA) printOrderTaxes.value.hasReteICA = printOrder.value.hasReteICA || false
  }

  function setPrintOrderType (type, isReceipt = false) {
    printOrderType.value = type
    printOrderIsReceipt.value = isReceipt
  }

  function setShowPrintOrder (show, isCampina) {
    if (isCampina !== undefined) {
      if (!isCampina) {
        if (printOrder?.value?.invoice?.document) {
          setPrintOrderType('order', false)
        } else {
          setPrintOrderType('order', true)
        }
      }
    }
    printOrderShow.value = show
  }

  return { data, getOrders, setFilters, createOrder, initData, pageInfo, setPage, updateOrder, recordOrder, createInvoice, verifyStatusInvoice, createInvoiceStrong, createNote, updateNote, recordNote, correctNote, resendEmail, printOrder, printOrderType, printOrderTaxes, printOrderShow, printOrderIsReceipt, setPrintOrderType, setPrintOrder, setShowPrintOrder }
})
