import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useNotificationStore } from '@/stores/notification'

export const useLocationsStore = defineStore('locations', () => {
  const data = ref([])

  const graphql = useGraphqlStore()
  const notification = useNotificationStore()

  async function getLocations (startsWith) {
    try {
      const variables = { startsWith }
      const response = await graphql.sendRequest('GET_LOCATIONS_FILTERED', variables)

      if (!response?.data?.getLocationsFiltered) throw new Error('Error al obtener los ubicaciones, comunícate con nosotros para más información')
      data.value = response?.data?.getLocationsFiltered
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  return { data, getLocations }
})
