/* eslint-disable no-unused-vars */
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notification', () => {
  const types = ['info', 'success', 'warning', 'error']
  const defaultTime = 8000
  const stylePositions = {
    'top-left': 'top: 0; left: 0; flex-direction: column',
    'top-right': 'top: 0; right: 0; flex-direction: column',
    'top-center': 'top: 0; left: 0; right: 0; margin-left: auto; margin-right: auto; flex-direction: column',
    'bottom-left': 'bottom: 0; left: 0; flex-direction: column-reverse',
    'bottom-right': 'bottom: 0; right: 0; flex-direction: column-reverse',
    'bottom-center': 'bottom: 0; left: 0; right: 0; margin-left: auto; margin-right: auto; flex-direction: column-reverse'
  }

  const notifications = ref([])
  const gap = ref(10)
  const positionString = ref('bottom-right')
  const style = ref(stylePositions[positionString.value])

  function validate ({ text, type, time }) {
    if (!text) {
      console.warn('The notification text is empty, the notification not will be showed')
      return
    }

    if (types.indexOf(type) < 0) {
      console.warn(`The notification type "${type}" does not exits, the possible values are: ` + types.join(', ') + '')
      return
    }

    if (typeof time !== 'number') {
      console.warn('The time has to be a number')
      return
    }

    return true
  }

  function showNotification ({ text, type = types[0], time = defaultTime }) {
    const isValid = validate({ text, type, time })
    if (!isValid) return

    const id = new Date().valueOf()

    notifications.value.push({
      id,
      text,
      type,
      time,
      timeout: setTimeout(() => { deleteNotification(id, false) }, time)
    })
  }

  function deleteNotification (id, clearTime = true) {
    const position = notifications.value.findIndex(notification => notification.id === id)

    if (position >= 0) {
      if (clearTime) {
        const notification = notifications.value[position]
        clearTimeout(notification.timeout)
      }

      notifications.value.splice(position, 1)
    }
  }

  function setPosition (newPosition) {
    if (!stylePositions[newPosition]) {
      console.warn('The notification position does not exits, the possible values are: ' + Object.keys(stylePositions).join(', ') + '')
      return
    }

    style.value = stylePositions[newPosition]
    positionString.value = newPosition
  }

  return {
    notifications,
    positionString,
    style,
    gap,
    showNotification,
    setPosition,
    deleteNotification
  }
})
