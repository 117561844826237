export default {
  SEND_VERIFICATION_CODE: `#graphql
  query SendVerificationCode($dni: String!) {
    sendVerificationCode(dni: $dni)
  }`,

  LOGIN: `#graphql
  query login($dni: String!, $code: String!) {
    login(dni: $dni, code: $code)
  }`
}
