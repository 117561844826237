import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useGraphqlStore } from '@/stores/graphql'
import { useUserStore } from '@/stores/user'

export const useConfigsStore = defineStore('configs', () => {
  const data = ref({
    justSavePOS: false,
    valuesWithTaxes: false
  })

  const graphql = useGraphqlStore()
  const user = useUserStore()

  async function getConfigs () {
    try {
      const variables = { nit: user.company.nit }
      const response = await graphql.sendRequest('GET_CONFIGS', variables)

      if (!response?.data?.getConfigs) {
        data.value = { justSavePOS: false, valuesWithTaxes: false }
        return
      }

      data.value = response.data.getConfigs
    } catch (error) {
      console.log(error)
    }
  }

  return { data, getConfigs }
})
