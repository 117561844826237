import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useTokenStore } from '@/stores/token'

export const useUserStore = defineStore('user', () => {
  const token = useTokenStore()

  const data = ref({
    name: '',
    dni: '',
    companies: []
  })

  const company = ref({})

  function setData () {
    const payload = token.getPayload(token.getToken())

    if (!payload) return
    if (!payload.name || !payload.dni || !payload.companies) return

    data.value.name = payload.name
    data.value.dni = payload.dni
    data.value.companies = payload.companies

    const index = sessionStorage.getItem('companyIndex')
    if (index >= 0 && index) setCompany(index)

    return true
  }

  function initData () {
    token.removeToken()

    data.value.name = ''
    data.value.dni = ''
    data.value.companies = []

    sessionStorage.removeItem('companyIndex')
  }

  function setCompany (index) {
    sessionStorage.setItem('companyIndex', index)
    company.value = data.value.companies[index]
    company.value.index = index
  }

  return { data, setData, company, setCompany, initData }
})
