export default {
  GET_DATA: `#graphql
  query getUsers($filters: Filters!, $page: Int!, $nit: String!) {
    getUsers(filters: $filters, page: $page, nit: $nit) {
      docs {
        dni,
        name,
        type,
        addresses,
        phones,
        emails,
        companies {
          name,
          nit,
          permissions
        }
        active
      },
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }
  }`
}
