export default {
  GET_DATA: `#graphql
  query getCustomers($filters: Filters!, $page: Int!, $nit: String!) {
    getCustomers(filters: $filters, page: $page, nit: $nit) {
      docs {
        documentType,
        documentNumber,
        verificationDigit,
        personType,
        name,
        commercialName,
        emails,
        phones,
        addresses,
        location,
        department,
        active,
        reteFuente,
        reteIVA,
        reteICA
      },
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }
  }`,

  GET_FILTERED: `#graphql
  query getCustomersFiltered($search: String!, $nit: String!) {
    getCustomersFiltered(search: $search, nit: $nit) {
      documentType,
      documentNumber,
      verificationDigit,
      personType,
      name,
      commercialName,
      emails,
      phones,
      addresses,
      location,
      department,
      reteFuente,
      reteIVA,
      reteICA
    }
  }`,

  CREATE: `#graphql
  mutation createCustomer($input: InputCustomer!, $nit: String!) {
    createCustomer(input: $input, nit: $nit) {
      documentNumber,
      documentType,
      name
    }
  }`,

  UPDATE: `#graphql
  mutation updateCustomer($input: InputUpdateCustomer!, $nit: String!) {
    updateCustomer(input: $input, nit: $nit) {
      documentType,
      documentNumber,
      verificationDigit,
      personType,
      name,
      commercialName,
      emails,
      phones,
      addresses,
      location,
      department,
      reteFuente,
      reteIVA,
      reteICA
    }
  }`
}
